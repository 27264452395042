/* 10 Nov Change Start */
.outstanding_main h6{
    color: #7a7a7a;
    font-weight: 600;
    letter-spacing: 1px;
}
/* 10 Nov Change End */

/* 11 Nov Change Start */
/* Request Edit */
.edit_request .modal-title{
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0.5px;
    line-height: 30px;
    color: var(--duotax-black-3);
    margin-bottom: 0px;
}

.btn-close{
    opacity: 1;
    width: 1em;
    height: 1em;
    padding: 0px!important;
}

.edit_request .modal-footer .orange_btn{
    width: inherit;
    padding: 12px 20px;
}

.edit_request textarea.form-control{
    height: inherit;
}
/* 11 Nov Change End */
