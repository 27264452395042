.header_main .navbar{
    background-color: #fff;
    margin-left: auto;
    padding: 13px 30px;
}

.header_main form{
    display: flex;
    align-items: center;
}

.header_main form input{
    padding: 9px 10px;
    min-width: 260px;
    border-radius: 4px;
    border: 0px;
    border: 1px solid #c3c3c3;
    margin-right: 10px;
    height: 45px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.header_main form input:focus-visible{
    outline: none;
}

.header_main form input::placeholder{
    font-weight: 600;
    letter-spacing: 0.5px;
}

.header_main .header_section{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.header_main form button{
    padding: 9px 20px;
    border-radius: 4px;
    border: 0px;
    background: #000;
    color: #fff;
    font-size: 14px;
    height: 45px;
}

.call{
    background-color: var(--duotax-orange);
    display: inline-block;
    padding: 10px 15px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-right: 15px;
}

.call img{
    margin-right: 10px;
}

.call:hover{
    color: #fff;
}

.user_name{
    display: inline-block;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-right: 15px;
    color: var(--duotax-black);
}

.user_name:hover{
    background-color: #f4f4f4;
    color: var(--duotax-black);
}

.user_name img{
    width: 25px;
    margin-right: 15px;
}

.setting{
    display: inline-block;
}

.setting img{
    width: 20px;
}

.right_menu{
    display: flex;
    align-items: center;
}

.res-user{
    margin-right: 20px;
}

.navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}

@media(max-width: 1199px){
    .header_main .navbar{
        justify-content: end;
    }
    .header_section .navbar-nav{
        display: none;
    }
}

@media(min-width: 1200px){
    .res-user{
        display: none;
    }
}