/* 15 Nov change Start */
.missing_property{
    background-color: var(--duotax-white);
    display: block;
    padding: 12px 10px;
    text-align: center;
    color: var(--duotax-orange);
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    border: 1px solid var(--duotax-white);
}

.missing_property:hover{
    color: var(--duotax-orange);
}
/* 15 Nov change End */