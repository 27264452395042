.sidebar_logo{
    max-width: 150px;
    margin: 20px auto;
    display: block;
}

/* .ant-layout-sider{
    background: #fff;
    width: 260px;
    max-width: 260px;
    min-width: 260px;
    flex: 0 0 260px;
    min-height: 100vh;
    z-index: 9999;
} */

.ant-layout-sider.ant-layout-sider-dark.sidebar_main{
    background: #fff;
    width: 260px!important;
    max-width: 260px!important;
    min-width: 260px!important;
    flex: 0 0 260px!important;
    min-height: 100vh;
    /* 11 Nov Change Start */
    z-index: 2;
    /* 11 Nov Change End */
}

.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-below.ant-layout-sider-zero-width.sidebar_main{
    flex: 0 0 0px!important;
    max-width: 0px!important;
    min-width: 0px!important;
    width: 0px!important;
}

.sidebar_main .ant-menu{
    margin-top: 25px;
}

.sidebar_main .ant-menu-item{
    padding: 5px 24px;
    height: inherit;
}

.sidebar_main .ant-menu-item a{
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #374047;
}

.sidebar_main .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: rgba(255,102,0,0.1);
}

.sidebar_main .ant-menu-vertical .ant-menu-item::after, 
.sidebar_main .ant-menu-vertical-left .ant-menu-item::after, 
.sidebar_main .ant-menu-vertical-right .ant-menu-item::after, 
.sidebar_main .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid rgba(255,102,0,1);
}

.sidebar_main .ant-menu-item-selected a, 
.sidebar_main .ant-menu-item-selected a:hover{
    color: rgba(255,102,0,1);
}

.ant-menu-item a:hover{
    color: rgba(255,102,0,1);
}

@media(max-width: 1199px){
    .ant-layout-sider.ant-layout-sider-dark.sidebar_main{
        position: absolute;
    }
}